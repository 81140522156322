function hasReference() {
    var ref = getReferenceCode();
    if(ref && ref != 'null'){
        return true;
    }
    return false;
}

if(hasReference()) {
    var socket = io('https://www.nptunnel.com:8443', {secure: true});
    socket.on('connect', function () {
        socket.emit('spec', {
            ses: getCookie('nptunnel_session'),
            vid: NP_VIEW,
            ref: getReferenceCode(),
            url: window.location.href
        });
    });
}

function isMobileBrowser()
{
    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
        isMobile = true;

    return isMobile;
}
function isCookieEnabled()
{
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled){
        document.cookie = "testcookie";
        cookieEnabled = document.cookie.indexOf("testcookie") != -1;
    }
    return cookieEnabled
}
function isLocalStorageEnabled()
{
    var test = 'test';
    try{
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

function getURIReferenceCode() {
    var uri = new URI(window.location.href);
    var params = uri.search(true);
    if(params.npref)
        return params.npref;
    if(params.ref)
        return params.ref;
    return false;
}

function getReferenceCode()
{
    if(ref = getURIReferenceCode())
        return ref;
    if(Cookies.get("np_ref"))
        return Cookies.get("np_ref");
    if(localStorage.np_ref)
        return localStorage.np_ref;
    return null;
}
function getLanguage()
{
    var uri = new URI(window.location.href);
    return uri.segment(0);
}
var browserInfo = {
    fingerprint: null,
    ref_code: getReferenceCode(),
    location_href: window.location.href,
    last_cookie: Cookies.get('np_ref'),
    cookie: Cookies.get("np_ref"),
    local_storage: localStorage.np_ref,
    is_cookie_enabled: + isCookieEnabled(),
    is_local_storage_enabled: + isLocalStorageEnabled(),
    mobile_browser: + isMobileBrowser(),
    app_version: navigator.appVersion,
    user_agent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    online: + navigator.onLine,
    java_enabled: + navigator.javaEnabled(),
    height_screen: window.screen.availHeight,
    width_screen: window.screen.availWidth,
    color_depth: screen.colorDepth,
    pixel_depth: screen.pixelDepth
};
function saveBrowserInfo(data){
    // if it has not yet been saved by the same page
    if(localStorage.last_url != window.location.href || Cookies.get('np_ref') != getURIReferenceCode()){
        if(isLocalStorageEnabled()){
            localStorage.setItem("last_url", window.location.href);
        }
        $.post('/api/v3/affiliate-access-logs/create?lng='+getLanguage(), data);
        Cookies.set("np_ref", getReferenceCode());
        localStorage.setItem("np_ref", getReferenceCode());
    }
}
$(function(){
    new Fingerprint2({
        excludeAdBlock: true,
        excludeLanguage: true,
        excludeScreenResolution: true,
        excludeAvailableScreenResolution: true,
        excludeTimezoneOffset: true
    }).get(function (result, components) {
        browserInfo.fingerprint = result;
        saveBrowserInfo(browserInfo);
    });
});
