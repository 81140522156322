//Chama ao carregar todo o HTML
$(document).ready(function(){
    
	
	//mobile?
	var mobile = false;
	var mobile_wp = false;
	var mobile_ios = false;
	var mobile_android = false;
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (/windows phone/i.test(userAgent)) { mobile_wp = true; mobile = true; }
    if (/android/i.test(userAgent)) { mobile_android = true; mobile = true; }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) { mobile_ios = true; mobile = true; }
	if (mobile) $("body").addClass("mobile"); else $("body").addClass("desktop");
	
	
	//menu lng
	$("#menu .lng .atual a").click(function(){
		$(this).parents(".lng").toggleClass("open");
		return false;
	});
	
	
	//submenu
	//monta submenus "externos"
	var subid = 0;
	$("#menu .menu li ul").each(function(){
		subid++;
		$(this).parents("li:first").find(">a:first").attr("href","#submenu"+subid).addClass("com-submenu");
		$("#header").after('<div class="submenu" id="submenu'+subid+'"></div>');
		var $sub = $("#submenu"+subid);
        $(this).clone().appendTo($sub);
    });

	$("#menu .menu > li > a.com-submenu").click(function(){
		if ($(window).width()<768) { //se é mobile, abre junto com o menu
			if ($(this).parents("li:first").find("ul").length>0) {
				$(this).parents("li:first").toggleClass("open");
				return false;
			}
		} else { //se é desktop, abre a barra de menu
			var $a = $(this);
			var id = $a.attr("href");
			var $sub = $(id);
			$(".submenu:not("+id+")").hide();
			$sub.slideToggle(300);
			return false;
		}
	});
	
	
	//menu mobile
	$("#menu-mobile").click(function(){
		$("#menu").toggleClass("open");
	});
	
	
	//form select
	$(".form-select select").each(function(){
        var $select = $(this);
		var $fs = $select.parents(".form-select");
		var $option = $select.find("option:selected");
		var val = $option.val();
		var text = $option.text();
		var img = $option.data("img");
		if (img!==undefined && img!="") text = '<img src="'+img+'">';
		$fs.append('<div class="form-control form-control-mask">'+text+'</div>');
		var $mask = $fs.find(".form-control-mask");
		if (!mobile) {
			$fs.append('<ul></ul>');
			var $ul = $fs.find("ul");
			$select.find("option").each(function(index){
				var sel = ""; if ($(this).is(":selected")) sel = "active";
				var otext = $(this).text();
				var oimg = $(this).data("img");
				//alert(oimg);
				if (oimg!==undefined && oimg!="") otext = '<img src="'+oimg+'">';
                $ul.append('<li data-index="'+index+'" data-value="'+$(this).val()+'" class="'+sel+'">'+otext+'</li>');
            });
			$mask.click(function(){
				$fs.toggleClass("open");
			});
			$ul.find("li").click(function(){
				var index = $(this).data("index");
				$select.find("option:eq("+index+")").prop("selected",true);
				$select.change();
				$ul.find("li.active").removeClass("active");
				$(this).addClass("active");
				$fs.removeClass("open");
			});
		}
		$select.change(function(){
			var $op = $select.find("option:selected");
			text = $op.text();
			img = $op.data("img");
			if (img!==undefined && img!="") text = '<img src="'+img+'">';
			$mask.html(text);
		});
    });
	$("body").click(function(){
		$(".form-select.open").removeClass("open");
	});
	$(".form-select").click(function(e){
		e.stopPropagation();
	});
	
	
	//form file
	$(".form-file input[type=file]").each(function(){
        var $select = $(this);
		var $fs = $select.parents(".form-file");
		var val = $select.val();
		if (val=='') val = 'Procurar foto...';
		$fs.append('<div class="form-control form-control-mask">'+val+'</div>');
		var $mask = $fs.find(".form-control-mask");
		$select.change(function(){
			var text = $select.val();
			$mask.html(text);
		});
    });
	
	
	//form valida
	//versão básica de validação que apenas verifica se o campo com a classe .valida está vazio.
	//em seguida, no submit, verifica se todos campos com a classe .valida estão com a classe .ok
	//ajustar conforme as necessidades. as classes nos campos são a .erro e .ok
	
	//valida o campo na saída do foco
	$("form input[type=text].valida, form input[type=email].valida, form input[type=file].valida, form input[type=password].valida, form textarea.valida").blur(function(){
		var $input = $(this);
		if ($input.val()=="") {
			$input.removeClass("ok")
			$input.addClass("erro");
		} else {
			$input.removeClass("erro");
			$input.addClass("ok");
		}
	});
	
	//remove o erro se o foco volta para o campo
	$("form .erro").focus(function(){
		$(this).removeClass("erro");
	});
	
	//valida o envio
	$("form").submit(function(){
		var $f = $(this);
		var ok = true;
		$f.find(".valida").each(function(){
            if (!$(this).hasClass("ok")) {
				$(this).removeClass("ok");
				$(this).addClass("erro");
				ok = false;
			}
        });
		if (!ok) alert("Verifique os campos assinalados.");
		return ok;
	});
	
	
	//janelas modal
	$(".modal").each(function(){
		var $modal = $(this);
		//coloca overlay se não existe
		$modal.append('<div class="overlay"></div>');
		//faz alguns ajustes na janela para prepará-la
		$modal.wrapInner('<div class="base"><div class="base"></div></div>');
    });
	$(".abrir-modal").click(function(e){
		var id = $(this).attr("href");
		$(id).addClass("open");
		e.preventDefault();
	});
	$("body").on("click",".modal .fechar, .modal .overlay",function(){ //note que coloquei um .overlayX ali. remove o X se quiser que o modal feche também clicando no fundo escuro, e não só no botão de fechar
		$(this).parents(".modal").removeClass("open");
	});

    $(".modal_payments").each(function(){
        var $modal = $(this);
        //coloca overlay se não existe
        $modal.append('<div class="overlay"></div>');
        //faz alguns ajustes na janela para prepará-la
        $modal.wrapInner('<div class="base"><div class="base"></div></div>');
    });
    $("body").on("click",".modal_payments .fechar",function(){ //note que coloquei um .overlayX ali. remove o X se quiser que o modal feche também clicando no fundo escuro, e não só no botão de fechar
        $(this).parents(".modal_payments").removeClass("open");
    });

	//iguala a altura de alguns elementos
	$(window).on("load resize",function(){
		if ($("#planos .plano .info").length>0) match_height("#planos .plano .info");
	});
	
	
	//campo cupom
	$(".modal-plano .cupom .temcupom.nao").prop("checked",true);
	$(".modal-plano .cupom .temcupom").change(function(){
		var $janela = $(this).parents(".modal");
		if ($janela.find(".cupom .temcupom:checked").hasClass("sim")) {
			$janela.find(".campocupom").show();
		} else {
			$janela.find(".campocupom").hide();
		}
	});
	
	
});


//janela modal automatica
//essa função é um atalho. abre uma janela modal apenas com parâmetros passados pela função, sem precisar adicionar manualmente html ao documento.
function modal(titulo,texto) {
	var html = '<div class="modal open"><div class="base"><div class="base"><div class="janela"><div class="fechar"><img src="img/modal_fechar.png"></div><h2 class="tit1">'+titulo+'</h2><p><br>'+texto+'<br><br></p></div></div></div><div class="overlay"></div></div>';
	$("body").append(html);
}


//essa função faz com que todos elementos do seletor informado tenham obrigatoriamente a mesma altura
function match_height(selector) {
	var $objs = $(selector);
	$objs.css("height","auto");
	var maior_h = 0;
	$objs.each(function(){
		var h = $(this).outerHeight();
		if (h>maior_h) maior_h = h;
	});
	$objs.css("height",maior_h);
}



//Chama ao carregar todas imagens e includes
$(window).load(function(){
	//inicia cycles
	$(".cycle").cycle();
	//loading
	$("#loading").fadeOut(600);
	if(typeof window.npAfterLoad === 'function') {
		window.npAfterLoad();
	}
});

var clipboard = new Clipboard('.copiar');
clipboard.on('success', function(e) {
	$(".copy-ok").slideDown();
});

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header').outerHeight();

$(window).on('scroll',function(e) {
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();
    
    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;
    
    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('header').removeClass('nav-down').addClass('nav-up');
        $('.mob-menu').removeClass('mob-menu-down').addClass('mob-menu-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('header').removeClass('nav-up').addClass('nav-down');
            $('.mob-menu').removeClass('mob-menu-up').addClass('mob-menu-down');
        }
    }
    
    lastScrollTop = st;
}

var supportedGames = document.getElementsByClassName("supported-games")

function OrientationChange(){
	if(window.innerHeight > window.innerWidth){
	    for (i = 0; i < supportedGames.length; i++) {
	        supportedGames[i].className = "supported-games"
	        supportedGames[i].className += " col-md-3 col-sm-6 col-xs-6 game-item";
	    }
	}else{
	    for (i = 0; i < supportedGames.length; i++) {
	        supportedGames[i].className = "supported-games"
	        supportedGames[i].className += " col-md-3 col-sm-4 col-xs-4 game-item";
	    }
	}
}

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	OrientationChange();
}

window.addEventListener('orientationchange', OrientationChange);


var ListaNavegacaoItem = document.getElementsByClassName("regua-navegacao-item");
var ListaMobMenu = document.getElementsByClassName("mob-menu");

for (i = 0; i < ListaNavegacaoItem.length; i++) {
    ListaNavegacaoItem[i].addEventListener("click", function(){switchMenu(ListaNavegacaoItem,this)});
}
function switchMenu(list,obj){
    var number = 0;
    for (i = 0; i < list.length; i++) {
        list[i].className = "regua-navegacao-item";
        if(list[i].id==obj.id){
            number = i;
        }
    }
    obj.className += " active";
    
    for (i = 0; i < ListaMobMenu.length; i++) {
        ListaMobMenu[i].style.display = "none";
    }
    console.log(number);
    if(number>0){
        ListaMobMenu[number-1].style.display = "block";
    }
}

var footer = document.querySelector('footer');
var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.bottom < (window.innerHeight || document.documentElement.clientHeight)
    );
};

if(isInViewport(footer)){
	footer.style.position = "absolute";
}